<script>
const sidebarMenu = [
  { path: '/home', icon: 'fa fa-home', title: 'หน้าหลัก' },
  { path: '/provinces', icon: 'fas fa-user', title: 'สรุปผลนับคะแนน นายก อบจ.' },
  // { path: '/areas', icon: 'fas fa-users', title: 'สรุปผลนับคะแนน ส.อบจ.' },
  {
    path: '/president',
    icon: 'fa fa-th',
    title: 'บันทึกข้อมูล นายก อบจ.',
    auth: true,
    roles: ['SuperAdmin'],
    children: [
      { path: '/president/unit', title: '1.1 บันทึก/ยืนยัน คะแนน', auth: true, roles: ['SuperAdmin'] },
      { path: '/president/sub_district', title: '1.2 สรุปคะแนน รายตำบล', auth: true, roles: ['SuperAdmin'] },
      { path: '/president/area', title: '1.3 สรุปคะแนน รายเขต', auth: true, roles: ['SuperAdmin'] },
      { path: '/president/district', title: '1.4 สรุปคะแนน รายอำเภอ', auth: true, roles: ['SuperAdmin'] },
    ],
  },
  // {
  //   path: '/council',
  //   icon: 'fa fa-hdd',
  //   title: '2. บันทึกข้อมูล ส.อบจ.',
  //   auth: true,
  //   roles: ['SuperAdmin'],
  //   children: [
  //     { path: '/council/unit', title: '2.1 บันทึก/ยืนยัน คะแนน', auth: true, roles: ['SuperAdmin'] },
  //     { path: '/council/sub_district', title: '2.2 สรุปคะแนน รายตำบล', auth: true, roles: ['SuperAdmin'] },
  //     { path: '/council/area', title: '2.3 สรุปคะแนน รายเขต', auth: true, roles: ['SuperAdmin'] },
  //     { path: '/council/district', title: '2.4 สรุปคะแนน รายอำเภอ', auth: true, roles: ['SuperAdmin'] },
  //   ],
  // }
  { path: '/users', icon: 'fas fa-user', title: 'ผู้ใช้งานระบบ', auth: true, roles: ['root'] },
]

export default sidebarMenu
</script>
